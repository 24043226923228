@tailwind base;
  @tailwind components;
  @tailwind utilities;

  
@layer base {
  

  :root  {
    --background: 164 100% 95%;
    --foreground: 164 5% 0%;
    --card: 164 50% 90%;
    --card-foreground: 164 5% 10%;
    --popover: 164 100% 95%;
    --popover-foreground: 164 100% 0%;
    --primary: 164 68.2% 34.5%;
    --primary-foreground: 0 0% 100%;
    --secondary: 164 30% 70%;
    --secondary-foreground: 0 0% 0%;
    --muted: 126 30% 85%;
    --muted-foreground: 164 5% 35%;
    --accent: 126 30% 80%;
    --accent-foreground: 164 5% 10%;
    --destructive: 0 100% 30%;
    --destructive-foreground: 164 5% 90%;
    --border: 164 30% 50%;
    --input: 164 30% 18%;
    --ring: 164 68.2% 34.5%;
    --radius: 0.5rem;
  }
  .dark  {
    --background: 164 50% 5%;
    --foreground: 164 5% 90%;
    --card: 164 50% 0%;
    --card-foreground: 164 5% 90%;
    --popover: 164 50% 5%;
    --popover-foreground: 164 5% 90%;
    --primary: 164 68.2% 34.5%;
    --primary-foreground: 0 0% 100%;
    --secondary: 164 30% 10%;
    --secondary-foreground: 0 0% 100%;
    --muted: 126 30% 15%;
    --muted-foreground: 164 5% 60%;
    --accent: 126 30% 15%;
    --accent-foreground: 164 5% 90%;
    --destructive: 0 100% 30%;
    --destructive-foreground: 164 5% 90%;
    --border: 164 30% 18%;
    --input: 164 30% 18%;
    --ring: 164 68.2% 34.5%;
    --radius: 0.5rem;
  }
  
}


  @layer base {
    * {
      @apply border-border;
    }
    body {
      @apply bg-background text-foreground;
    }
  }